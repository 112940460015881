import NoDataView from 'components/error/NoDataView';
import * as d3 from 'd3-array';
import { eachDayOfInterval, endOfDay, format, startOfDay } from 'date-fns';
import { useScreenSize } from 'hooks';
import isNil from 'lodash.isnil';
import memoize from 'lodash.memoize';
import { Layout } from 'plotly.js';
import { FC } from 'react';
import Plot from 'react-plotly.js';
import { Colors } from 'shared/constants';
import { TCSSProperties } from 'shared/interfaces';
import styled from 'styled-components';
import { PlantHeightDtoWithSystemDto } from './PlantHeightReport';
type PlantHeightLineChartProps = {
  data: PlantHeightDtoWithSystemDto[];
  isGroupByweek?: boolean;
};

const PlantHeightLineChart: FC<PlantHeightLineChartProps & TCSSProperties> = ({
  data,
  style,
  className,
}) => {
  const { isMobile } = useScreenSize();
  if (isNil(data) || data.length === 0) {
    return <NoDataView />;
  }
  const hovertemplate =
    '' +
    '<b>%{text}</b><br>' +
    `<i>height (m)</i>: %{y}<br>` +
    '<extra></extra>';
  const timestamps = data
    .map((plantHeightDto) => plantHeightDto.timestamps)
    .flat()
    .map((timestamp) => timestamp.getTime());
  if (timestamps.length === 0) {
    return <NoDataView />;
  }
  const binsByDay = generateTimeBinsByDay(timestamps);
  const plotData: Plotly.Data[] = data
    .sort((a, b) => {
      const customerAndZoneA = `${a.customerCode} - ${a.zoneName}`;
      const customerAndZoneB = `${b.customerCode} - ${b.zoneName}`;
      return customerAndZoneA.localeCompare(customerAndZoneB);
    })
    .map((plantHeightDto) => {
      // const customerAndZone = `${plantHeightDto.zoneName} - ${plantHeightDto.customerCode}`;
      const customerAndZone = `${plantHeightDto.customerCode} - ${plantHeightDto.zoneName}`;
      const text = Array(plantHeightDto.timestamps.length).fill(
        customerAndZone
      );
      const sortedHeightAndTimes = sortHeightAndTimes(
        createHeightTimeSeriesData(
          plantHeightDto.mins,
          plantHeightDto.timestamps
        )
      );
      const { xValTimeBins, yVals } = binHeightAndTimes(
        sortedHeightAndTimes,
        binsByDay
      );
      return {
        x: xValTimeBins,
        y: yVals,
        text: text,
        name: customerAndZone,
        textposition: 'none',
        hovertemplate: hovertemplate,
        type: 'scatter',
      };
    });
  return (
    <StyledPlot
      className={className}
      style={style}
      isMobile={isMobile}
      data={plotData}
      layout={isMobile ? mobilePlotLayout : plotLayout}
      config={{
        modeBarButtonsToRemove: [
          'toImage',
          'zoom2d',
          'pan2d',
          'select2d',
          'lasso2d',
          'zoomIn2d',
          'zoomOut2d',
          'autoScale2d',
        ],
        // autosizable: false,
        displaylogo: false,
        displayModeBar: isMobile ? false : true,
        responsive: true,
      }}
    />
  );
};
/**
 * Sorts an array of HeightTimeSeriesData objects based on the timestamp property in ascending order.
 *
 * @param {HeightTimeSeriesData[]} heightAndTimes - The array of HeightTimeSeriesData objects to be sorted.
 * @returns {HeightTimeSeriesData[]} The sorted array of HeightTimeSeriesData objects.
 */
function sortHeightAndTimes(heightAndTimes: HeightTimeSeriesData) {
  return heightAndTimes.sort(
    (a, b) => a.timestamp.getTime() - b.timestamp.getTime()
  );
}
interface IStyledPlot {
  isMobile?: boolean;
}

const StyledPlot = styled(Plot)<IStyledPlot>`
  .modebar {
    transform: ${({ isMobile }) =>
      isMobile ? 'translate(-50px, 15px)' : 'translate(20px, 00px)'};
  }
`;

const mobilePlotLayout: Partial<Layout> = {
  title: {
    text: 'Distance to canopy (m) M',
    x: 0.035,
    y: 0.95,
    font: {
      family: 'Poppins-SemiBold',
      color: Colors.black,
    },
  },
  font: {
    family: 'Poppins',
  },
  margin: {
    t: 40,
    l: 0,
    b: 70,
    r: 0,
  },
  yaxis: {
    autorange: 'reversed',
    domain: [-0.1, 1.1],
  },
};

const plotLayout: Partial<Layout> = {
  title: {
    text: 'Distance to canopy (m) D',
    x: 0,
    y: 0.95,
    font: {
      family: 'Poppins-SemiBold',
      color: Colors.black,
    },
  },
  font: {
    family: 'Poppins',
  },
  margin: {
    t: 40,
    l: 30,
    b: 35 * 2,
    r: 0,
  },
  yaxis: {
    autorange: 'reversed',
    domain: [-0.1, 1.1],
  },
  height: 350,
  width: 350 * 2,
};
interface HeightTimeSeriesDatum {
  height: number;
  timestamp: Date;
}
type HeightTimeSeriesData = HeightTimeSeriesDatum[];
/**
 * Creates a height time series data from the given heights and timestamps.
 *
 * @param {number[]} heights - The array of heights.
 * @param {Date[]} timestamps - The array of timestamps.
 * @returns {HeightTimeSeriesData} The generated height time series data.
 */
function createHeightTimeSeriesData(
  heights: number[],
  timestamps: Date[]
): HeightTimeSeriesData {
  return heights.map((height, index) => ({
    height,
    timestamp: timestamps[index],
  }));
}
/**
 * Bins the given array of height and time objects by day and returns the corresponding x and y values.
 *
 * @param {Array<HeightTimeSeriesDatum>} heightAndTimes - The array of height and time objects.
 * @param {d3.HistogramGeneratorNumber<HeightTimeSeriesDatum, number>} bins - The histogram generator for the bins.
 * @returns {{ xValTimeBins: string[], yVals: Array<{ avgHeight: number }> }} - The binned x and y values.
 */
const binHeightAndTimes = memoize(
  (
    heightAndTimes: { height: number; timestamp: Date }[],
    bins: d3.HistogramGeneratorNumber<HeightTimeSeriesDatum, number>
  ) => {
    const xValTimeBins = bins(heightAndTimes).map((bin) => {
      const startDate = new Date(bin.x0!);
      const DATE_RANGE_FORMAT = 'MMM-dd';
      const startDateText = format(startDate, DATE_RANGE_FORMAT);
      return `${startDateText}`;
    });

    const yVals: (number | null)[] = bins(heightAndTimes).map((bin) => {
      // average values between the bin x0, x1
      return d3.mean(bin.map((d) => d.height)) || null;
    });
    return { xValTimeBins, yVals };
  }
);
interface HeightTimeSeriesDatum {
  height: number;
  timestamp: Date;
}
/**
 * Generates bins for the given array of height and timestamp objects, grouped by day.
 * Each bin represents a day and contains all height and timestamp objects within that day.
 *
 * @param {number[]} timestamps - The array of objects with height and timestamp.
 * @returns {d3.HistogramGeneratorNumber<HeightTimeSeriesDatum, number>} The histogram generator for the bins,
 * which are determined based on the timestamps, with each bin covering a single day.
 */
const generateTimeBinsByDay = (timestamps: number[]) => {
  const sortedTimestamps = timestamps.sort();
  const startDate = startOfDay(sortedTimestamps.at(0)!).valueOf();
  const endDate = endOfDay(sortedTimestamps.at(-1)!).valueOf();
  const timeRangeList = eachDayOfInterval({
    start: startDate,
    end: endDate,
  }).map((date) => date.getTime());
  return d3
    .bin<HeightTimeSeriesDatum, number>()
    .thresholds(timeRangeList)
    .domain([startDate, endDate])
    .value((d) => d.timestamp.getTime());
};
export default PlantHeightLineChart;

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { formatDateInFullDayTime } from 'api/system_logs';
import { headerMessageVar, showAllSystemsVar } from 'cache';
import { DateSelector, TextInput } from 'components/common';
import DesktopLayout from 'components/layout/desktop_layout/DesktopLayout';
import { useTimeframeContext } from 'contexts';
import { useEffect, useState } from 'react';

import useGetPlantHeight, { PlantHeightDto } from 'api/plant_height';
import { useGetSystems } from 'api/system';
import { FilterGroup, useFilters } from 'components/common/FilterGroup';
import { minutesToMilliseconds, sub } from 'date-fns';
import { SystemDto } from 'shared/interfaces';
import { useDebouncedCallback } from 'use-debounce';
import PlantHeightLineChart from './PlantHeightLineChart';
import PlantHeightTable from './PlantHeightTable';

const PLANT_HEIGHT_CHANGE_THRESHOLD = 300;
const INITIAL_PLANT_HEIGHT_METERS = 0.3;
const PlantHeightReport = () => {
  const isFetchAllSystems = showAllSystemsVar();
  const { now, startDate, endDate, setStartDate, setEndDate } =
    useTimeframeContext();
  const { systemUidToSystem } = useGetSystems(isFetchAllSystems);
  const zoneUidToSystem = Array.from(systemUidToSystem.values()).reduce(
    (acc, system) => {
      if (!acc.has(system.zoneUid)) {
        acc.set(system.zoneUid, system);
      }
      return acc;
    },
    new Map<string, SystemDto>()
  );

  const { zoneUidToPlantHeight, loading } = useGetPlantHeight({
    zoneUidToSystem,
    startDate,
    endDate,
  });
  const plantHeightDtoWithSystemDtos: PlantHeightDtoWithSystemDto[] =
    Array.from(zoneUidToPlantHeight.values()).map((plantHeightDto) => {
      return {
        ...plantHeightDto,
        ...zoneUidToSystem.get(plantHeightDto.zoneUid)!,
      };
    });

  useEffect(() => {
    const timer = setInterval(() => {
      setStartDate(sub(now, { days: 7 }));
      setEndDate(now);
    }, minutesToMilliseconds(30));

    setStartDate(sub(now, { days: 7 }));
    setEndDate(now);
    return () => clearInterval(timer);
  }, [setStartDate, setEndDate]);
  const { systemFilter, zoneFilter, customerFilter } = useFilters();
  const [plantHeightMetersThreshold, setPlantHeightMetersThreshold] = useState(
    INITIAL_PLANT_HEIGHT_METERS
  );

  headerMessageVar(
    `Plant Height Report: ${formatDateInFullDayTime(startDate)} - ${formatDateInFullDayTime(endDate)}`
  );

  const systemUids = plantHeightDtoWithSystemDtos.map(
    ({ systemUid }) => systemUid
  );
  const zoneNames = plantHeightDtoWithSystemDtos.map(
    ({ zoneName }) => zoneName
  );
  const customerNames = plantHeightDtoWithSystemDtos.map(
    ({ customerCode }) => customerCode
  );

  const filteredPlantHeightDtoWithSystemDtos: PlantHeightDtoWithSystemDto[] =
    plantHeightDtoWithSystemDtos
      .filter(({ systemUid }) => systemFilter(systemUid))
      .filter(({ zoneName }) => zoneFilter(zoneName))
      .filter(({ customerCode }) => customerFilter(customerCode))
      .filter(({ mins }) => mins.at(-1)! <= plantHeightMetersThreshold);
  const handlePlantHeightChange = useDebouncedCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      console.log(Number(event.target.value));
      setPlantHeightMetersThreshold(Number(event.target.value));
    },
    PLANT_HEIGHT_CHANGE_THRESHOLD
  );

  return (
    <DesktopLayout>
      <div
        id="plant-height-report-controls"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px 20px',
          marginBottom: '20px',
        }}
      >
        <div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateSelector
              startDateValue={startDate}
              endDateValue={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </LocalizationProvider>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
          <FilterGroup>
            <FilterGroup.Systems systemUids={systemUids} />
            <FilterGroup.Customers customerNames={customerNames} />
            <FilterGroup.Zones zoneNames={zoneNames} />
          </FilterGroup>
          <TextInput
            id="plantHeight"
            label="Plant height (m)"
            data-testid="plant-height"
            style={{
              width: '10%',
            }}
            defaultValue={INITIAL_PLANT_HEIGHT_METERS.toString()}
            focused={true}
            onChange={handlePlantHeightChange}
          />
        </div>
      </div>
      <div
        id="plant-height-report"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          gap: '20px 20px',
        }}
      >
        {loading && <p>Loading...</p>}
        {!loading && (
          <>
            <div>
              <PlantHeightLineChart
                data={filteredPlantHeightDtoWithSystemDtos}
              />
            </div>
            <div>
              <PlantHeightTable
                data={filteredPlantHeightDtoWithSystemDtos}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          </>
        )}
      </div>
    </DesktopLayout>
  );
};

export type PlantHeightDtoWithSystemDto = PlantHeightDto & SystemDto;
export default PlantHeightReport;

export enum ESystemColumn {
  System = 'system',
  SystemUid = 'systemUid',
  CustomerID = 'customer',
  Customer = 'customerName',
  Zone = 'zoneName',
  LastMeasurementRun = 'lastRun',
  LastLog = 'lastLog',
  Level = 'level',
  Timestamp = 'timestamp',
  Module = 'module',
  LogStatus = 'systemLogStatus',
  SpyderStatus = 'systemSpyderStatus',
  SypderStatusCreatedAt = 'systemSpyderStatusCreatedAt',
  SpyderStatusUpdatedAt = 'systemSpyderStatusUpdatedAt',
  SpysderCanopyDistance = 'systemSpyderCanopyDistance',
  SpysderCanopyDistanceTimestamp = 'systemSpyderCanopyDistanceTimestamp',
}

export enum ESort {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}

export interface IConfig<T> {
  key: string;
  direction: ESort;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueMapFunction?: (item: T) => number | string;
}

export type TConfigMap<T> = Record<string, IConfig<T>>;

export enum ELogLevels {
  INFO = 1,
  DEBUG = 2,
  WARNING = 3,
  ERROR = 4,
  ERROR_NO_LOGS = 5,
}
export enum ESystemLogStatus {
  NOMINAL = 'NOMINAL',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  ERROR_NO_LOGS = 'ERROR_NO_LOGS',
  UNKNOWN = '---',
}

export enum ESystemSpyderStatus {
  HMI_PAUSED = 'HMI_PAUSED',
  HMI_HOME = 'HMI_HOME',
  STATIONARY = 'STATIONARY',
  STATIC = 'STATIC',
  DYNAMIC = 'DYNAMIC',
  UNKNOWN = 'UNKNOWN',
  E_STOP_PRESSED = 'E_STOP_PRESSED',
  IDLE_AFTER_AUTO_RESTART = 'IDLE_AFTER_AUTO_RESTART',
}

export interface SystemDto {
  systemId: number;
  systemUid: string;
  customerId: number;
  customerName: string;
  customerCode: string;
  lastRun: Optional<Date>;
  lastLog: Optional<Date>;
  zoneId: number;
  zoneName: string;
  zoneUid: string;
  systemStatus: ESystemSpyderStatus;
  systemStatusCreatedAt: Date;
  systemStatusUpdatedAt: Date;
  logDetails: Optional<LogDetails>;
  metadata: any;
  softwareVersion: string;
  hardwareVersion: string;
}

export interface LogEntity {
  level: number;
  message: string;
  module: string;
  timestamp: number;
}
export interface LogDto {
  level: number;
  message: string;
  module: string;
  timestamp: Date;
}
export interface LogDetails {
  logs: LogDto[];
  reasonList: string[];
  logLevel: ELogLevels;
  systemLogStatus: ESystemLogStatus;
}
export interface IIndexable {
  [key: string]: string | number;
}

export interface IOptions {
  value: string;
  label: string;
}

export interface ISystemStateData {
  customerId: number;
  zoneId: number;
  systemUid: string;
}

export interface ILogStateData {
  lastLog: number | undefined;
  systemUid: string;
  logTimestamp: number | undefined;
}

export interface IExpressionValues {
  [key: string]: {
    N?: string;
    S?: string;
  };
}

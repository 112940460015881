import { FieldNoteDto } from 'api/field_notes';
import NoDataView from 'components/error/NoDataView';
import * as d3 from 'd3-array';
import {
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfDay,
  format,
  startOfDay,
} from 'date-fns';
import { useScreenSize } from 'hooks';
import isNil from 'lodash.isnil';
import { Layout } from 'plotly.js';
import { FC } from 'react';
import Plot from 'react-plotly.js';
import { Colors } from 'shared/constants';
import { TCSSProperties } from 'shared/interfaces';
import styled from 'styled-components';

type InterventionByZoneBarChartProps = {
  data: FieldNoteDto[];
  isGroupByweek?: boolean;
};

const InterventionByZoneBarChart: FC<
  InterventionByZoneBarChartProps & TCSSProperties
> = ({ data, style, className, isGroupByweek }) => {
  if (isNil(data) || data.length === 0) {
    return <NoDataView />;
  }
  const dataTimestamps = data.map(({ updatedAt }) => updatedAt.getTime());
  const customerAndZone = Array.from(
    new Set(
      data.map(({ system }) => `${system.zoneName} - ${system.customerCode}`)
    )
  ).sort();

  const { isMobile } = useScreenSize();

  let bins = generateTimeBinsByDay(dataTimestamps);
  if (isGroupByweek) {
    bins = generateTimeBinsByWeek(dataTimestamps);
  }
  const xValTimeBins = bins(dataTimestamps).map((bin) => {
    const startDate = new Date(bin.x0!);
    const endDate = new Date(bin.x1!);
    const DATE_RANGE_FORMAT = 'MMM-dd';
    const startDateText = format(startDate, DATE_RANGE_FORMAT);
    const endDateText = format(endDate, DATE_RANGE_FORMAT);
    return `${startDateText} - ${endDateText}`;
  });
  const yValByZones = customerAndZone.map((zone) => {
    return {
      zone,
      values: getYValsByZone(data, zone, bins),
    };
  });
  const hovertemplate =
    '' + '<b>%{text}</b><br>' + `<i>Count</i>: %{y}<br>` + '<extra></extra>';
  const plotData: Plotly.Data[] = yValByZones.map(({ zone, values }) => {
    const text = Array(values.length).fill(zone);
    return {
      x: xValTimeBins,
      y: values,
      text: text,
      name: zone,
      textposition: 'none',
      hovertemplate: hovertemplate,
      type: 'bar',
    };
  });
  return (
    <StyledPlot
      className={className}
      style={style}
      isMobile={isMobile}
      data={plotData}
      layout={isMobile ? mobilePlotLayout : plotLayout}
      config={{
        modeBarButtonsToRemove: [
          'toImage',
          'zoom2d',
          'pan2d',
          'select2d',
          'lasso2d',
          'zoomIn2d',
          'zoomOut2d',
          'autoScale2d',
        ],
        autosizable: false,
        displaylogo: false,
        displayModeBar: isMobile ? false : true,
        responsive: true,
      }}
    />
  );
};

/**
 * Generates bins for the given array of timestamps, grouped by day.
 *
 * @param {number[]} dataTimestamps - The array of timestamps.
 * @returns {d3.HistogramGeneratorNumber<number, number>} The histogram generator for the bins,
 * with each bin representing a day and containing the number of logs within that day.
 */
const generateTimeBinsByDay = (dataTimestamps: number[]) => {
  const sortedTimestamps = dataTimestamps.sort();
  const startDate = startOfDay(sortedTimestamps.at(0)!).valueOf();
  const endDate = endOfDay(sortedTimestamps.at(-1)!).valueOf();
  const timeRangeList = eachDayOfInterval({
    start: startDate,
    end: endDate,
  }).map((date) => date.getTime());
  return d3.bin().thresholds(timeRangeList).domain([startDate, endDate]);
};
const generateTimeBinsByWeek = (dataTimestamps: number[]) => {
  const sortedTimestamps = dataTimestamps.sort();
  const startDate = startOfDay(sortedTimestamps.at(0)!).valueOf();
  const endDate = endOfDay(sortedTimestamps.at(-1)!).valueOf();
  const timeRangeList = eachWeekOfInterval({
    start: startDate,
    end: endDate,
  }).map((date) => date.getTime());
  return d3.bin().thresholds(timeRangeList).domain([startDate, endDate]);
};
/**
 * Calculates the number of field notes for each bin by updated at date.
 *
 * @param {FieldNoteDto[]} logData - The array of log data.
 * @param {string} zone - The zone to filter the log data by.
 * @param {d3.HistogramGeneratorNumber<number, number>} bins - The histogram generator for binning the log data.
 * @returns {number[]} An array of the number of field notes in each bin.
 */
const getYValsByZone = (
  logData: FieldNoteDto[],
  zone: string,
  bins: d3.HistogramGeneratorNumber<number, number>
): number[] => {
  const updatedAtDataByZone = logData
    .filter(
      ({ system }) => `${system.zoneName} - ${system.customerCode}` === zone
    )
    .map(({ updatedAt }) => updatedAt.getTime());
  return bins(updatedAtDataByZone).map((bin) => bin.length);
};

interface IStyledPlot {
  isMobile?: boolean;
}

const StyledPlot = styled(Plot)<IStyledPlot>`
  width: ${({ isMobile }) => (isMobile ? '50%' : '100%')};
  .modebar {
    transform: ${({ isMobile }) =>
      isMobile ? 'translate(-50px, 15px)' : 'translate(20px, 00px)'};
  }
`;

const mobilePlotLayout: Partial<Layout> = {
  title: {
    text: 'Interventions by Zone',
    x: 0.035,
    y: 0.95,
    font: {
      family: 'Poppins-SemiBold',
      color: Colors.black,
    },
  },
  barmode: 'group',
  legend: {
    x: -0.1,
    y: 1,
    orientation: 'h',
    font: {
      color: Colors.dark2,
    },
  },
  font: {
    family: 'Poppins',
  },
  margin: {
    t: 40,
    l: 0,
    b: 70,
    r: 0,
  },
};

const plotLayout: Partial<Layout> = {
  title: {
    text: 'Interventions by Zone',
    x: 0,
    y: 0.95,
    font: {
      family: 'Poppins-SemiBold',
      color: Colors.black,
    },
  },
  barmode: 'group',
  legend: {
    x: 0.1,
    y: 1.1,
    orientation: 'h',
    font: {
      color: Colors.dark2,
    },
  },
  font: {
    family: 'Poppins',
  },
  margin: {
    t: 40,
    l: 30,
    b: 35,
    r: 0,
  },
  height: 600,
};

export default InterventionByZoneBarChart;

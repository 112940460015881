/* eslint-disable camelcase */
import { parseISO } from 'date-fns';
import { useGetMeasurementRunsBetweenDatesByZoneQuery } from 'graphql/generated/react_apollo';
import isNil from 'lodash.isnil';
import { useEffect, useState } from 'react';

interface GetLineChartDataInput {
  parameters: {
    zoneUids: string[];
    start: Date;
    end: Date;
    typeConfig: {
      metadataPath: string;
      convertFromUnit: (value: number) => number;
    };
  };
  skip: boolean;
}
interface TimeSeriesDatum {
  timestamp: Date;
  min: number;
  max: number;
  mean: number;
}

interface TimeSeriesData {
  data: TimeSeriesDatum[];
  zoneUid: string;
}
export const useGetMeasurementRunsMetadataBetweenDatesByZones = ({
  parameters: { zoneUids, start, end, typeConfig },
  skip,
}: GetLineChartDataInput) => {
  const [zoneUidToTimeSeriesData, setZoneUidToTimeSeriesData] = useState<
    Map<string, TimeSeriesData>
  >(new Map());
  const { data, ...result } = useGetMeasurementRunsBetweenDatesByZoneQuery({
    skip: skip || isNil(typeConfig.metadataPath),
    variables: {
      zone_uids: zoneUids,
      start,
      end,
      metadata_path: typeConfig.metadataPath,
    },
  });

  const { convertFromUnit } = typeConfig;

  useEffect(() => {
    if (!data || result.loading) {
      return;
    }
    const zoneUidToTimeSeriesDataTemp = new Map<string, TimeSeriesData>();
    data.measurement_run
      .filter(({ metadata }) => metadata)
      .forEach(({ zone, metadata, start_time }) => {
        let timeSeriesData = zoneUidToTimeSeriesDataTemp.get(zone.uid);
        if (!timeSeriesData) {
          // Create new time series data
          timeSeriesData = {
            data: [
              {
                timestamp: parseISO(start_time),
                min: convertFromUnit(metadata.min),
                max: convertFromUnit(metadata.max),
                mean: convertFromUnit(metadata.mean),
              },
            ],
            zoneUid: zone.uid,
          };
        } else {
          // Update existing time series data
          timeSeriesData.data.push({
            timestamp: parseISO(start_time),
            min: convertFromUnit(metadata.min),
            max: convertFromUnit(metadata.max),
            mean: convertFromUnit(metadata.mean),
          });
        }
        zoneUidToTimeSeriesDataTemp.set(zone.uid, timeSeriesData);
      });

    setZoneUidToTimeSeriesData(zoneUidToTimeSeriesDataTemp);
  }, [data, result.loading]);

  return { zoneUidToTimeSeriesData, ...result };
};
